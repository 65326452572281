import React from 'react'
import * as PropTypes from "prop-types"
import { graphql } from "gatsby"
import Layout from "../components/layout"

const propTypes = {
  data: PropTypes.object.isRequired,
}

const Exhibitions = ({ data }) => (
  <Layout> 
    <div className="exhibitions-wrapper">
    <div id="collections">
      <h2> Selected Collections </h2>
      <div id="collection-list"
        dangerouslySetInnerHTML={{__html: data.contentfulCollecties.childContentfulCollectiesItemsTextNode.childMarkdownRemark.html}}>
      </div>
    </div>

    <div> 
      <div id="exhibitions">
      <h2> Exhibitions </h2>
        <div id="exhibition-list">
          {data.allContentfulTentoonstellingen.edges.map((item, i) => (
            <div className="exhibition-item">
              <h3>{item.node.year}</h3>
              <div dangerouslySetInnerHTML={{__html: item.node.exhibitions.childMarkdownRemark.html}}>
              </div>
            </div>
          ))}
        </div>
      </div>
     </div>
  </div>
  </Layout>
)

Exhibitions.propTypes = propTypes;

export default Exhibitions;

export const query = graphql`
  query {
    contentfulCollecties {
      childContentfulCollectiesItemsTextNode {
        childMarkdownRemark {
          html
        }
      }
    }

    allContentfulTentoonstellingen(
      sort: {fields: [year], order:DESC}
    ) {
      edges {
        node {
          year
          exhibitions {
            childMarkdownRemark {
              html
            }
          }
        }
      }
    }
  }
`